"use client";
import React from "react";
import HeroWavesSVG from "@/components/ui/SVGIcons/HeroWaves";
import Constants from "@/utils/Constants";
// import { SparklesCore } from "../sparkles";
import { BsApp } from "react-icons/bs";
import { IoSparkles } from "react-icons/io5";

import dynamic from "next/dynamic";
import { socialBaseManPath } from "@/utils/NavigationUtils";
import Image from "next/image";
import Link from "next/link";

const SparklesCore = dynamic(
  () => import("../sparkles").then((mod) => mod.SparklesCore),
  {
    ssr: false,
  }
);

const SimpleLink = dynamic(() => import("./SimpleLink"), {
  ssr: false,
});

type Props = { overrideBGColor?: string };

export default function CheckoutAppsHero({ overrideBGColor }: Props) {
  interface Point {
    x: number;
    y: number;
  }

  function calculateCircularPositions(
    centerX: number,
    centerY: number,
    radius: number,
    itemCount: number,
    offsetAngle: number = 0
  ): Point[] {
    const positions: Point[] = [];
    const angleIncrement = (2 * Math.PI) / itemCount;

    for (let i = 0; i < itemCount; i++) {
      const angle = i * angleIncrement;
      const x = centerX + radius * Math.cos(angle + offsetAngle);
      const y = centerY + radius * Math.sin(angle + offsetAngle);
      positions.push({ x, y });
    }

    return positions;
  }
  function renderTitle() {
    return (
      <div className="mb-12 flex w-full flex-col space-y-8">
        <div className="relative mx-auto mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-accent p-4 text-accent-content">
          <BsApp size={60} className="absolute inset-0 m-auto" />
          <IoSparkles size={30} className="absolute inset-0 m-auto" />
        </div>
        <h1 className="text-2xl font-bold  tracking-tighter text-base-content md:text-4xl lg:text-5xl xl:text-6xl ">
          {`In the meantime 👀 this app`} <br /> {`powered by`}
          <strong className="ml-2 bg-gradient-to-r from-secondary to-warning bg-clip-text font-extrabold text-transparent ">
            {`MLTask`}
          </strong>
        </h1>
      </div>
    );
  }
  return (
    <section
      className={`relative flex w-full grow flex-col items-center ${
        overrideBGColor ?? Constants.defaultBGColor
      } `}
    >
      <div className="pointer-events-none absolute inset-0 mt-0 h-full w-full">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={25}
          className="h-full w-full"
          particleColor="#FFFFFF"
        />
      </div>
      <div className="mx-auto flex w-full flex-col px-4 py-12 text-center sm:px-6 md:px-12 lg:px-24 lg:py-24 ">
        {renderTitle()}

        <div className="mx-auto flex h-64 w-64 flex-col items-center justify-center ">
          {/* {renderSocialManIcon()} */}
          <Link
            className="my-auto flex aspect-square w-full  flex-col space-y-2 rounded-md  bg-[linear-gradient(110deg,oklch(var(--s)),45%,oklch(var(--s)),50%,oklch(var(--s)))] bg-[length:200%_100%] p-2 text-center text-sm font-medium shadow transition-colors hover:bg-[linear-gradient(110deg,oklch(var(--p)),45%,oklch(var(--s)),50%,oklch(var(--p)))] focus:outline-none  focus:ring  sm:w-auto"
            href={socialBaseManPath()}
          >
            <Image
              className={`h-8/12 m-auto w-8/12 `}
              alt={`image`}
              // loader={myLoader}
              src={`/images/socialManHero.svg`}
              width="0"
              height="0"
              style={{ objectFit: "contain" }}
              sizes="(max-width: 768px) 100vw,
(max-width: 1200px) 50vw,
33vw"
            />
            <span className="text-2xl font-bold text-accent md:text-3xl lg:text-4xl xl:text-5xl">
              Socialman
            </span>
          </Link>
        </div>
        <div className="mt-10 flex justify-center gap-4 ">
          <SimpleLink href={socialBaseManPath()} text={"Check it out 👀"} />
        </div>
      </div>
      <HeroWavesSVG fillColorCSS="fill-base-400" inverted={true} />
    </section>
  );
}
