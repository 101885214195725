"use client";
import { useFetchPublicTasks } from "@/hooks/QueryHooks/ReactQueryWrapperhooks";
import { IBackendManagerInterface } from "@/utils/BackendManager";
import { raleway } from "@/utils/Constants";
import { toastError, toastInfo } from "@/utils/ToastsManager";
import { emailRegex } from "@mltask/core/Utils/RegexHelper";
import { LazyMotion, domAnimation, m } from "framer-motion";
import dynamic from "next/dynamic";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonWithLoading from "../ButtonWithLoading";
import EmailInvalidIndicator from "../Login/EmailInvalidIndicator";
import AtSVG from "../SVGIcons/AtSVG";
// import { BackgroundBeams } from "@/components/ui/background-beams";
// import { FaCircleCheck } from "react-icons/fa6";
// import { WavyBackground } from "../wavy-background";
// import SimpleHeader from "../Sections/SimpleHeader";

const TaskOutputCard = dynamic(
  () => import("../Tasks/TaskOutputCard/TaskOutputCard"),
  {
    ssr: false,
  }
);

const SimpleTextInput = dynamic(
  () => import("@/components/ui/Input/SimpleTextInput"),
  {
    ssr: false,
  }
);
var BackendManager: IBackendManagerInterface;
import("@/utils/BackendManager").then((lib) => {
  BackendManager = lib.default;
});

type Props = {};

export default function WaitlistHero({}: Props) {
  const { data: pagedTasks, isError: isErrorQuery } =
    useFetchPublicTasks(undefined);
  pagedTasks;
  const randomIndex: number = useMemo(
    () => getRandomIndex(pagedTasks?.pages?.[0]?.tasks.length ?? 0),
    [pagedTasks?.pages]
  );
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function renderLogo() {
    return (
      <div className={`flex flex-col ${raleway.className}`}>
        <div className="mx-auto flex w-full flex-row font-bold">
          {Array.from("MLTask").map((x, index) => {
            const pClassName = index < 2 ? "text-accent" : "text-primary";

            return (
              <div
                className={"flex"}
                style={{ animationDelay: `${index * 500}ms` }}
                key={index}
              >
                <span
                  className={`relative ${pClassName} text-3xl transition-all sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl`}
                >
                  {x}
                </span>
              </div>
            );
          })}
        </div>
        {renderDeadline()}
      </div>
    );
  }
  async function handleJoiningWaitlist() {
    setLoading(true);
    try {
      await BackendManager.addToWaitlist(email);
      toastInfo("Yaaaay, you are on the waitlist 🥳");
    } catch (error) {
      console.error("Failed to add to waitlist with error", error);
      toastError();
    }
    setLoading(false);
  }
  function renderProductValueProp() {
    const bulletPoints = [
      "Effortlessly complete tasks with cutting-edge 🤖 models",
      "Enhance productivity with streamlined 🤖 tools: image generation, ChatGPT, transcription, TTS, and more",
      "Monetize your 🤖 prompts and maximize your earnings",
    ];

    return (
      <div className="flex flex-col py-4 text-xl">
        <p className="text-2xl">
          Effortlessly complete tasks with cutting-edge 🤖 models like ChatGPT,
          transcription, TTS, and more while monetizing your 🤖 prompts and
          maximize your earnings
        </p>
        {/* {bulletPoints.map((x, index) => (
          <div key={index} className="flex items-start space-x-2">
            <div className={`mt-1 flex text-secondary`}>
              <FaCircleCheck />
            </div>
            <p className={`text-xl text-base-content`}>{x}</p>
          </div>
        ))} */}
      </div>
    );
  }
  function renderWaitlistValueProp() {
    return (
      <div className="flex flex-col">
        <div className="divider"></div>
        <div className="flex w-full flex-col">
          <span className="base-content text-xl font-extrabold leading-6 text-secondary lg:text-3xl">
            {`Be the first to know when it's ready`}
          </span>
        </div>
        {renderCTA()}
      </div>
    );
  }
  function renderCTA() {
    return (
      <div className="mt-4 flex flex-col">
        <form
          noValidate
          className="ng-untouched ng-pristine ng-valid flex flex-row space-y-0"
          onSubmit={handleSubmit((data) => {
            handleJoiningWaitlist();
          })}
        >
          <div className="flex w-full  ">
            <SimpleTextInput
              registrar={register("email", {
                required: true,
                pattern: emailRegex,
                onChange: (e) => {
                  setEmail(e.target.value);
                },
              })}
              errorSection={
                errors["email"] && <EmailInvalidIndicator email={email} />
              }
              inputType="email"
              placeholder="Ex. john@smith.com"
              value={email}
              SVGELement={<AtSVG size={5} strokeWidth={2} />}
              additionalInputClassName={"rounded-r-none"}
            />
            <ButtonWithLoading
              type="submit"
              isLoading={loading}
              className={`btn mt-2.5 rounded-l-none  bg-gradient-to-r from-primary to-[color-mix(in_oklab,oklch(var(--p)),black_7%)] text-base font-semibold text-primary-content transition-all duration-200 hover:opacity-90 focus:opacity-90 focus:outline-none`}
            >
              Join the waitlist!
            </ButtonWithLoading>
          </div>
        </form>
      </div>
    );
  }
  function renderDeadline() {
    return (
      <div className={"flex"}>
        <span
          className={`relative text-3xl transition-all sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl`}
        >
          {/* is launching soon 🚀 */}
          is in private beta 🥷
        </span>
      </div>
    );
  }
  function getRandomIndex(length: number): number {
    return Math.floor(Math.random() * length);
  }

  function renderSideTask() {
    if (pagedTasks == undefined) return;
    let targetTask = pagedTasks.pages?.[0]?.tasks[randomIndex];
    if (targetTask == undefined) return;
    return (
      <div className="mx-auto my-auto hidden h-full w-1/4  items-center justify-center lg:flex">
        <TaskOutputCard
          {...targetTask}
          isThreeD={true}
          isCompact={true}
          hideInput={true}
          showCreator={false}
        />
      </div>
    );
  }
  function renderMainHero() {
    return (
      <div className="mx-auto flex w-full flex-col p-4  text-left lg:w-3/5">
        <LazyMotion features={domAnimation}>
          <m.h1
            initial={{ opacity: 0.5, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="space-y-4 "
          >
            {renderLogo()}
            {renderProductValueProp()}
            {renderWaitlistValueProp()}
          </m.h1>
        </LazyMotion>
      </div>
    );
  }
  return (
    <div className="flex w-full flex-row items-center justify-center rounded-md bg-base-200 p-8 antialiased">
      {/* <BackgroundBeams /> */}
      {renderMainHero()}
      {renderSideTask()}
    </div>
  );
}
